* {
  font-family: 'proxima-soft', Verdana, sans-serif !important;
}

.mdc-form-field {
  label {
    font-weight: 800 !important;
    font-size: 1rem !important;
  }
}

/*****************************************************
                    RADIO BUTTONS
*****************************************************/

.mdc-radio__outer-circle {
  color: linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%) !important;
}

.mdc-radio__inner-circle {
  background-color: linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%) !important;
}

.mat-mdc-radio-button {
  .mat-radio-ripple {
    display: none !important;
  }
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  display: none !important;
}

/*****************************************************
                       OTHER
*****************************************************/

.mdc-snackbar {
  color: theme('colors.amber.300') !important;
  &.snackbar-success,
  &.snackbar-info,
  &.snackbar-warning {
    .mat-mdc-snack-bar-action {
      color: theme('colors.purple.300') !important;
    }
  }

  &.snackbar-error {
    .mdc-snackbar__surface {
      @apply tw-bg-red-700;
    }
    .mat-mdc-snack-bar-action {
      color: white !important;
    }
  }
}

#cdk-overlay-1 > mat-snack-bar-container .mdc-evolution-chip__text-label,
.mat-mdc-chip-action-label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.mat-expansion-panel {
  .mat-expansion-panel-header {
    height: 72px !important;
  }
}

.mat-expansion-panel-content {
  padding: 0 24px;
}

.mat-expanded {
  .mat-expansion-panel-content {
    min-height: 72px;
    padding: 12px 24px;
  }
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
  margin-left: 0 !important;
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
  border-bottom: 1px solid theme('colors.slate.200');
}

.mat-bottom-sheet-container {
  padding: unset !important;
}

.mat-pseudo-checkbox-minimal,
.mat-pseudo-checkbox,
mat-pseudo-checkbox {
  display: none !important;
}

.mat-mdc-option .mdc-list-item__primary-text,
.mat-mdc-menu-item-text {
  font-family: 'proxima-soft', Verdana, sans-serif !important;
  font-size: 14px !important;
  color: var(--Default-slate-grey-600, theme('colors.slate.500')) !important;
  letter-spacing: normal !important;
}

.mat-mdc-option.mdc-list-item--selected,
.btn-sort-active {
  background: white !important;
  border-radius: 4px;
  color: theme('colors.slate.500') !important;
  font-weight: 600 !important;
  &:hover {
    border-radius: 4px;
  }
}

.mat-mdc-option {
  border-radius: 4px;
  &:hover {
    border-radius: 4px;
  }
}

.btn-sort-active {
  .mat-mdc-menu-item-text {
    font-weight: 600 !important;
  }
}

.mat-mdc-select-panel {
  margin-top: 5px;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border: 1px solid var(--Default-slate-grey-300, theme('colors.slate.200')) !important;
  box-shadow:
    0 10px 30px 0 rgba(67, 80, 98, 0.08),
    0 2px 4px 0 rgba(67, 80, 98, 0.08) !important;
  padding: 8px !important;
}

.invite-dialog {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      overflow: hidden !important;
    }
  }
}
.event-request-dialog,
.change-parent-event-dialog {
  .mat-mdc-dialog-container {
    max-width: calc(100vw - theme('size.6'));
    max-height: calc(100vh - theme('size.6'));
    margin: auto;
    .mat-mdc-dialog-surface {
      border-radius: 12px !important;
      overflow: hidden;
      height: fit-content;
    }
  }
}

.actions-panel {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      border-radius: 12px !important;
      border: 1px solid var(--Default-slate-grey-300, theme('colors.slate.200')) !important;
    }
  }

  .actions-backdrop {
    background-color: transparent !important;
  }
}

.mat-mdc-menu-panel {
  margin-top: 10px;
  border: 1px solid #d7dee7;
  box-shadow:
    0 2px 4px rgba(67, 80, 98, 0.08),
    0 10px 30px rgba(67, 80, 98, 0.08) !important;
  border-radius: 12px !important;
  max-width: unset !important;
  .mat-mdc-menu-content {
    padding: 8px !important;
    .mat-mdc-menu-item {
      border-radius: 12px;
      .mat-mdc-menu-item-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          font-size: 16px;
          font-weight: 700;
        }
        ate-icon {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
      }
      &:hover {
        background: theme('colors.slate.50') !important;
        span {
          color: theme('colors.brand.maroon');
        }
        ate-icon {
          color: theme('colors.brand.maroon');
        }
      }
    }
  }
}

.menu-container {
  > button {
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: theme('colors.slate.500');
    background: white;
    border: 1.5px solid #d7dee7;
    border-radius: 4px;
    padding: 6px 12px;
    letter-spacing: normal;
    &:hover {
      background-color: white !important;
    }
  }
}

.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    border-radius: 8px !important;
    background: theme('colors.slate.900') !important;
    text-align: center !important;
  }
}

/**
 * Mat Tabs
 */

@mixin mat-tab-gradient-background {
  @apply tw-relative tw-border-transparent tw-bg-white tw-bg-clip-padding;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 2px;
    bottom: 0;
    left: 2px;
    z-index: -1;

    @apply -tw-m-0.5  tw-bg-gradient-to-r tw-from-brand-gradientfrom tw-to-brand-gradientto;
  }
}

.mat-mdc-tab-label-container {
  max-width: 1280px !important;
  margin: auto;
}

.mat-mdc-tab-body-wrapper {
  @apply tw-bg-slate-50;
  width: 100%;
  max-width: 1280px !important;
  margin: auto;
}

mat-tab-header > div > div > div {
  @apply lg:tw-pl-5;
  max-width: 1280px !important;
  margin: auto;
}

.mat-mdc-tab-header {
  background: white !important;

  border-bottom: 1px solid theme('colors.slate.300');
}

.mdc-tab {
  padding: 0 !important;

  .mdc-tab__ripple {
    display: none !important;
  }

  &.mdc-tab--active,
  &.mdc-tab--active.cdk-focused {
    @apply tw-text-base tw-font-bold tw-capitalize tw-tracking-normal tw-text-slate-600;

    .mdc-tab__content .mdc-tab__text-label {
      background: linear-gradient(90deg, #642b73 0%, #c6426e 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      @include mat-tab-gradient-background;
    }
  }

  .mdc-tab__content {
    .mdc-tab__text-label {
      @apply tw-grow-0 tw-py-3 tw-text-base tw-font-bold tw-capitalize tw-tracking-normal tw-text-slate-600 lg:tw-mx-8;
      color: theme('colors.slate.600') !important;
    }
  }

  .mdc-tab__content:hover + .mdc-tab-indicator {
    .mdc-tab-indicator__content--underline {
      @include mat-tab-gradient-background;
    }
  }
}

.events-tab-group {
  .mat-mdc-tab-body-content {
    padding: 20px !important;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../../../node_modules/@addtoevent/sass/sass/addtoevent';
@import '../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'mixins';
@import 'theme_main';
@import 'theme-overwrite';
@import 'animations';
@import 'base';
@import 'main';
@import 'lists';
@import 'forms';
@import "statuses";
@import "variables";
@include addtoevent;

// ******************************************************************
// **************************** STATUSES ****************************
// ******************************************************************

.icon-circle {
  border-radius: 20px;
  text-align: center;
  font-size: 10px;
  line-height: initial;
}

.status-base {
  border-radius: 8px;
  padding: 0 8px;
  p {
    font-size: 12px;
    line-height: 16px;
  }

  @include respond-above(lg) {
    padding: 0 10px 0 8px;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.status-indicator-basic {
  color: theme('colors.slate.600');
  background-color: theme('colors.slate.200');

  .icon-circle {
    background-color: theme('colors.slate.200');
    ate-icon {
      color: theme('colors.slate.600');
    }
  }
}

.status-indicator-circle {
  color: theme('colors.slate.600');
  background-color: theme('colors.slate.200');

  .icon-circle {
    background-color: theme('colors.slate.600');
    ate-icon {
      color: theme('colors.slate.200');
    }
  }
}

.status-archived-basic {
  color: theme('colors.slate.600');
  background-color: theme('colors.slate.100');

  .icon-circle {
    background-color: theme('colors.slate.100');
    ate-icon {
      color: theme('colors.slate.600');
    }
  }
}

.status-archived {
  color: theme('colors.slate.600');
  background-color: theme('colors.slate.100');

  .icon-circle {
    background-color: theme('colors.slate.500');
    ate-icon {
      color: theme('colors.white');
    }
  }
}

.status-navy {
  color: theme('colors.white');
  background-color: theme('colors.blue.600');

  .icon-circle {
    background-color: theme('colors.white');
    ate-icon {
      color: theme('colors.blue.600');
    }
  }
}

.status-warn {
  color: theme('colors.slate.800');
  background-color: theme('colors.amber.50');

  .icon-circle {
    background-color: theme('colors.amber.50');
    ate-icon {
      color: theme('colors.amber.600');
    }
  }
}

.status-alert {
  color: theme('colors.red.800');
  background-color: theme('colors.red.50');

  .icon-circle {
    background-color: theme('colors.red.50');
    ate-icon {
      color: theme('colors.red.600');
    }
  }
}

.status-success {
  color: theme('colors.green.800');
  background-color: theme('colors.green.50');

  .icon-circle {
    background-color: theme('colors.green.600');
    ate-icon {
      color: theme('colors.white');
    }
  }
}

.status-invited {
  color: theme('colors.violet.800');
  background-color: theme('colors.violet.100');

  .icon-circle {
    background-color: theme('colors.violet.100');
    ate-icon {
      color: theme('colors.violet.800');
    }
  }
}

.status-reported {
  color: theme('colors.slate.600');
  background-color: theme('colors.slate.200');

  .icon-circle {
    background-color: theme('colors.slate.600');
    ate-icon {
      color: theme('colors.slate.200');
    }
  }
}

.status-interested {
  color: theme('colors.teal.800');
  background-color: theme('colors.teal.100');

  .icon-circle {
    background-color: theme('colors.teal.100');
    ate-icon {
      color: theme('colors.teal.800');
    }
  }
}

.status-navy {
  color: theme('colors.white');
  background-color: theme('colors.blue.600');

  .icon-circle {
    background-color: theme('colors.white');
    ate-icon {
      color: theme('colors.blue.600');
    }
  }
}

.status-confirmed {
  color: theme('colors.blue.800');
  background-color: theme('colors.blue.50');

  .icon-circle {
    background-color: theme('colors.blue.50');
    ate-icon {
      color: theme('colors.blue.600');
    }
  }
}

.status-confirmed-no-icon {
  color: theme('colors.blue.800');
  background-color: theme('colors.blue.50');

  .icon-circle {
    background-color: theme('colors.blue.600');
  }
}

.status-matched {
  color: theme('colors.white');
  background-color: theme('colors.purple.700');

  .icon-circle {
    background-color: theme('colors.purple.700');
    ate-icon {
      color: theme('colors.white');
    }
  }
}

body {
  margin: 0;
  overflow-x: hidden;
}

.marketplace-page {
  ate-marketplace-page-header {
    margin-top: padding(medium);
  }

  ate-marketplace-filter-header {
    margin-top: padding(small);

    @include respond-above(sm) {
      margin-top: padding(medium);
    }
  }

  ate-pagination {
    margin-top: padding(base);
  }
}

ate-marketplace-page-header {
  margin-bottom: padding(medium);
}

.error-chunk-modal {
  background-color: white;
  opacity: 0.5 !important;
}

a {
  color: #a93a61;
  font-weight: bold;
}

// Modal overrides
ate-form-flow-modal {
  dialog::backdrop, .backdrop {
    overflow-y: hidden;
  }
  dialog[open], .dialog-wrapper.open .dialog {
    width: 720px;
    height: 620px;
    padding: 0;

    .body {
      width: 100%;
      margin: 0;
      max-height: 483px;
    }

    footer {
      display: flex;
      width: 100%;

      .back-button {
        width: fit-content;
      }
    }
  }
}

app-request-progress {
  display: block;
  width: 100vw;
  height: 100vh;
}

.btn-gradient {
  &::before {
    transition: background 200ms ease-in-out;
    content: '';
    position: absolute;
    background-color: white;
    @apply tw-bottom-0.5 tw-left-0.5 tw-right-0.5 tw-top-0.5 tw-rounded-md;
  }
  ate-icon[name='loading-spinner-v3'] {
    @apply tw-text-slate-400;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.48;
  }

  span {
    -webkit-text-fill-color: transparent;
  }
  &:hover {
    &::before {
      cursor: pointer;
      background-color: theme('colors.slate.hover');
    }
  }
}


.colorAteGradientText {
  background: var(--Default-Brand-Gradient, linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.greyScale {
  filter: grayscale(1);
}

.border-right-1px {
  border-right: 1px solid theme('colors.slate.300') !important;
}

.link-item {
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.animation-delay-0 {
  animation-delay: 0s;
}

.animation-delay-500ms {
  animation-delay: 500ms;
}

.animation-delay-1 {
  animation-delay: 1s;
}

.animation-delay-2 {
  animation-delay: 2s;
}

.animation-delay-3 {
  animation-delay: 3s;
}

.invisible {
  visibility: hidden;
}

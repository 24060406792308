@import 'mixins';
@import 'variables';

$header-height: 62px;

@include respond-above(xl) {
  $header-height: 80px;
}

.confirmation-dialog,
.invite-dialog {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      border-radius: 12px !important;
      box-shadow:
        0 10px 30px 0 rgba(67, 80, 98, 0.08),
        0 2px 4px 0 rgba(67, 80, 98, 0.08);
    }
  }
}

mat-drawer-container {
  overscroll-behavior: auto;
}

.mat-drawer-container {
  -webkit-overflow-scrolling: initial !important;
  height: calc(100vh - 60px);
  overflow: hidden;
  z-index: unset !important;

  @include respond-above(xl) {
    height: calc(100vh - 80px);
  }

  &.action-footer-present {
    height: calc(100vh - 156px);
  }

  &.invites-footer {
    margin-top: 0;
    height: calc(100vh - 155px);
  }

  &.invites-summary {
    margin-top: 0;
    height: calc(100vh - 83px);
  }

  &.no-header {
    margin-top: 0;
    height: calc(calc(var(--dvh, 1vh) * 100) - #{$header-height});
  }

  .invites-actions + .mat-drawer {
    height: calc(100vh - 155px);
  }

  .mat-drawer {
    position: fixed;
    top: 80px;
    z-index: 10;
    @include respond-below(lg) {
      height: 100vh;
      top: 0;
    }
  }
}

.fullheight {
  height: calc(var(--dvh, 1vh));
}

.fullheight-minus-header {
  height: calc(calc(var(--dvh, 1vh)) - 60px);

  @include respond-above(lg) {
    height: calc(calc(var(--dvh, 1vh)) - 80px);
  }
}

:root {
  .mat-drawer-backdrop {
    position: fixed;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.56);
    transition-duration: 0.2s;
  }
}

.mat-drawer-content {
  position: static !important;
}

.mat-bottom-sheet-container {
  @apply tw-rounded-t-xl tw-border tw-border-solid tw-border-slate-300;
}

#app-menu-sidebar {
  width: 100%;
  @include respond-above(lg) {
    width: 250px;
  }
  @include respond-above(xl) {
    width: 300px;
  }
}

.page-content {
  height: 100%;
  background-color: theme('colors.slate.50');

  marketplace-details {
    height: 100%;
  }

  &.full-page-view {
    flex: 1 1 0;
    min-height: initial;
    display: flex;

    @include respond-above(sm) {
      overflow: hidden;
    }
  }

  &.hide-footer {
    padding-bottom: 0;
  }
}

app-quote-request-container,
app-event-request-overview,
app-request-invites-progress,
app-event-request-inbox,
app-error,
app-quotes,
app-event-request-details {
  width: 100%;
}

app-quote-request,
app-quote-request-mobile {
  display: block !important;
  overflow-y: auto;
}

/********* INVITES *********/

.invites-body-header {
  position: relative;
  background: theme('colors.brand.purple');
  overflow: hidden;

  .bg-left {
    width: 214px;
    height: 346px;
    transform: rotate(-36deg);
    position: absolute;
    left: -80px;
    top: -250px;
    background: linear-gradient(to top, theme('colors.brand.gradientfrom'), theme('colors.brand.gradientto'));
    border-radius: 350px;

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      right: 8px;
      bottom: 8px;
      left: 8px;
      background-color: theme('colors.brand.purple');
      border-radius: 350px;
    }

    @include respond-above(sm) {
      top: -160px;
      left: 0;
    }
  }

  .bg-right {
    position: absolute;
    right: -100px;
    top: 30px;
    width: 275px;
    height: 275px;
    border-radius: 275px;
    background: linear-gradient(134deg, #6a287e 13.36%, #c6426e 72.04%);

    @include respond-above(sm) {
      width: 400px;
      height: 400px;
    }
  }
}

.invites {
  overflow: hidden;

  app-request-invites-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .invites-body-header {
    h2,
    p {
      color: white;
    }
  }

  h2 {
    font-size: 16px;
    @include respond-above(sm) {
      font-size: 30px;
    }
  }

  p {
    font-size: 16px;
  }

  .invites-body-header-details {
    position: relative;
    z-index: 1000;
  }

  .invites-body {
    position: relative;
    background-color: theme('colors.slate.50');
    overflow-y: auto;
    overflow-x: hidden;

    .carousel-container {
      margin-top: -80px;
      z-index: 1000;

      app-content-carousel {
        width: 100%;
      }
    }

    .invites-indicator {
      width: 96px;

      .close-invites {
        ate-icon {
          transition: background 200ms ease-in-out;

          &:hover {
            opacity: 0.4;
          }
        }
      }

      .icon-wrapper {
        border-radius: 50%;
        border: 2px solid theme('colors.slate.200');
        background: white;
      }

      .invites-indicator-last-step {
        border-radius: 50%;
        border: 3px solid theme('colors.slate.200');
        background: white;
      }

      .invites-indicator-timeline {
        width: 2px;
        background: #8d0a51;
        height: 100%;
        margin: 3px 0;
      }
    }

    .step-title {
      color: theme('colors.slate.700');
    }

    .skip-invites {
      color: theme('colors.slate.500') !important;
      text-decoration-line: underline;
    }

    .step-description {
      color: theme('colors.slate.500');
    }

    .invites-step {
      max-width: 100%;
      width: 650px;
      border-radius: var(--Radius-rounded-2xl, 16px);
      background: white;
    }

    .invites-step-complete {
      border: 2px solid var(--Default-green-700, theme('colors.green.600'));
      background: var(--Default-green-50, theme('colors.green.50'));
    }

    .invites-step-incomplete {
      border: 2px solid var(--Default-green-700, theme('colors.slate.200'));
    }

    .invites-step-active {
      border: var(--spacing-05, 2px) solid var(--Default-Brand-Gradient, #6a287e);
      box-shadow:
        0 10px 30px 0 rgba(67, 80, 98, 0.08),
        0 2px 4px 0 rgba(67, 80, 98, 0.08);
    }
  }

  .invites-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 32px);
    z-index: 1000;
    border-top: 1px solid var(--Default-slate-grey-300, theme('colors.slate.300'));
    background: var(--Default-White, white);

    .skip-button {
      min-width: 60px;
    }
  }
}

.tile-hover {
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  &:hover,
  &:focus {
    box-shadow:
      0 2px 4px 0 rgba(67, 80, 98, 0.08),
      0 10px 30px 0 rgba(67, 80, 98, 0.08);
  }
}
app-event-item {
  .tile-hover {
    cursor: pointer;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    box-shadow: inset 0 0 0 1px theme('colors.slate.300');

    &:hover,
    &:focus {
      box-shadow:
        inset 0 0 0 2px theme('colors.slate.800'),
        0 2px 4px 0 rgba(67, 80, 98, 0.08),
        0 10px 30px 0 rgba(67, 80, 98, 0.08);
    }
  }
}

.dialog-backdrop-dark {
  background-color: rgba(0, 0, 0, 0.56);
}

.eventTypeIcon {
  &.icon_anniversary {
    background-color: map-get($ateEventTypeIcons, 'anniversaryBg');
    border-color: map-get($ateEventTypeIcons, 'anniversaryBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'anniversaryColour') !important;
    }
  }

  &.icon_birthday_party {
    background-color: map-get($ateEventTypeIcons, 'birthdayBg');
    border-color: map-get($ateEventTypeIcons, 'birthdayBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'birthdayColour');
    }
  }

  &.icon_charity_event {
    background-color: map-get($ateEventTypeIcons, 'charityBg');
    border-color: map-get($ateEventTypeIcons, 'charityBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'charityColour');
    }
  }

  &.icon_childrens_party {
    background-color: map-get($ateEventTypeIcons, 'childrensBg');
    border-color: map-get($ateEventTypeIcons, 'childrensBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'childrensColour');
    }
  }

  &.icon_christening {
    background-color: map-get($ateEventTypeIcons, 'christeningBg');
    border-color: map-get($ateEventTypeIcons, 'christeningBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'christeningColour');
    }
  }

  &.icon_corporate_event {
    background-color: map-get($ateEventTypeIcons, 'corporateBg');
    border-color: map-get($ateEventTypeIcons, 'corporateBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'corporateColour');
    }
  }

  &.icon_festival {
    background-color: map-get($ateEventTypeIcons, 'festivalBg');
    border-color: map-get($ateEventTypeIcons, 'festivalBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'festivalColour');
    }
  }

  &.icon_hen_party {
    background-color: map-get($ateEventTypeIcons, 'henBg');
    border-color: map-get($ateEventTypeIcons, 'henBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'henColour');
    }
  }

  &.icon_stag_party {
    background-color: map-get($ateEventTypeIcons, 'stagBg');
    border-color: map-get($ateEventTypeIcons, 'stagBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'stagColour');
    }
  }

  &.icon_other_party {
    background-color: map-get($ateEventTypeIcons, 'otherPartyBg');
    border-color: map-get($ateEventTypeIcons, 'otherPartyBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'otherPartyColour');
    }
  }

  &.icon_education_event {
    background-color: map-get($ateEventTypeIcons, 'educationEventBg');
    border-color: map-get($ateEventTypeIcons, 'educationBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'educationColour');
    }
  }

  &.icon_unspecified_event {
    background-color: map-get($ateEventTypeIcons, 'unspecifiedBg');
    border-color: map-get($ateEventTypeIcons, 'unspecifiedBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'unspecifiedColour');
    }
  }

  &.icon_wedding {
    background-color: map-get($ateEventTypeIcons, 'weddingBg');
    border-color: map-get($ateEventTypeIcons, 'weddingBorder');

    ate-icon {
      color: map-get($ateEventTypeIcons, 'weddingColour');
    }
  }

  &.icon_inactive {
    background-color: theme('colors.slate.50');
    border-color: theme('colors.slate.200');

    ate-icon {
      color: theme('colors.slate.500') !important;
    }
  }
}

.main-event {
  border-bottom: 1px solid theme('colors.slate.300');
  padding: 12px;
  @include respond-above(sm) {
    padding: 24px;
  }
}

.event-list-item,
.main-event {
  background: theme('colors.white');
  transition: all 0.2s ease-in-out;

  .event-icon-container {
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;

    ate-icon {
      height: 24px;
      width: 24px;
    }

    @include respond-above(sm) {
      width: 70px;
      height: 70px;
  
      ate-icon {
        height: 32px;
        width: 32px;
      }
    }
  }

  .event-icon-container-new-request {
    flex-shrink: 0;
    width: 58px;
    height: 58px;
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;

    ate-icon {
      height: 28px;
      width: 28px;
    }
  }

  .event-item-details {
    h2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }

    h3 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }

    p {
      font-size: 12px;
      line-height: 16px;
      font-style: normal;
      font-weight: 400;
    }

    @include respond-above(sm) {
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

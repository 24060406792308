.ate-mat-radio-button-group-vertical {
  border: 1px solid theme('colors.slate.200');
  border-radius: 4px;

  .mat-mdc-radio-button {
    padding: 10px;
  }

  .mat-mdc-radio-button {
    background-color: white;
    transition: all 200ms ease-in-out;

    &:active,
    &:hover {
      background-color: theme('colors.slate.50');
    }

    .mat-radio-label-content {
      padding-left: 20px !important;
      font-weight: theme('fontWeight.bold');
    }
  }

  .mat-mdc-radio-checked {
    background-color: theme('colors.slate.50');
  }

  .mat-mdc-radio-button:not(:last-child) {
    border-bottom: 1px solid theme('colors.slate.200');
  }
}

/*****************************************************
                       BUTTONS
*****************************************************/

.close-icon {
  width: 24px;
  height: 24px;
}

// Base button class
.btn-action {
  position: relative;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  transition: all 200ms ease-in-out;

  &:focus {
    outline: 2px solid theme('colors.slate.800');
    outline-offset: 2px;
  }

  &:disabled {
    cursor: default;
  }
}

// Button size classes
.btn-action-sm {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 10px 16px;

  ate-icon {
    width: 20px;
    height: 20px;
  }
}

.btn-action-md {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 12px 24px;

  ate-icon {
    width: 24px;
    height: 24px;
  }
}

.btn-action-lg {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 14px 28px;

  ate-icon {
    width: 24px;
    height: 24px;
  }
}

.btn-primary {
  background: linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%);
  color: theme('colors.white');
  border: none !important;

  //Since Tailwind CSS and SASS do not share variables directly, color values must be hardcoded the in SASS.
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    border: unset !important;
    background: linear-gradient(90deg, #822a9d 0%, #d4547e 100%);
  }

  &:disabled {
    background: linear-gradient(90deg, rgba(#6a287e, 0.6) 0%, rgba(#c6426e, 0.6) 100%);
  }
}

.btn-secondary {
  background: theme('colors.white');
  color: theme('colors.white');
  border: 2px solid theme('colors.slate.300');

  span,
  ate-icon {
    color: theme('colors.slate.600');
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background: theme('colors.slate.100');
  }

  &:disabled {
    background: theme('colors.slate.100');

    span,
    ate-icon {
      color: theme('colors.slate.400') !important;
    }
  }
}

.btn-outline {
  border: none;
  background: linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%);
  position: relative;
  transition: all 200ms ease-in-out;

  &::before {
    content: '';
    @apply tw-bottom-0.5 tw-left-0.5 tw-right-0.5 tw-top-0.5;
    position: absolute;
    background-color: white;
    border-radius: 6px;
    transition: all 200ms ease-in-out;
  }

  span,
  ate-icon {
    background: linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
  }

  ate-icon {
    &.btn-outline-icon-left {
      color: theme('colors.brand.gradientfrom');
    }
    &.btn-outline-icon-right {
      color: theme('colors.brand.gradientto');
    }
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    border: unset !important;
    background: linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%);

    &::before {
      opacity: 0;
    }

    span,
    ate-icon {
      background: unset;
      background-clip: unset;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
      color: theme('colors.white');
    }
  }

  &:disabled {
    opacity: 0.48;
  }
}

.ate-btn {
  border-radius: 4px;
  max-width: 100%;
  height: 48px;
  cursor: pointer;
  padding: 12px 20px;
  font-weight: 800;
  font-size: 1rem;
  transition: all 200ms ease-in-out;

  &:focus {
    outline: 0;
  }
}

.btn-cancel {
  background-color: white;
  color: black;
  border: 2px solid theme('colors.slate.200');
}

.btn-submit {
  background: linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%);
  color: white;
  border: none !important;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%);
  }

  &:disabled {
    background: linear-gradient(90deg, theme('colors.brand.gradientfrom60') 0%, theme('colors.brand.gradientto60') 100%);
    cursor: default;
  }
}

.gradient-button {
  @apply tw-mt-5 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-gap-2.5 tw-rounded-lg tw-border-none tw-bg-gradient-to-r tw-from-brand-gradientfrom tw-to-brand-gradientto tw-py-3 tw-pl-4 tw-pr-5 tw-text-base tw-font-bold tw-text-white tw-transition-shadow tw-duration-200;

  &:hover {
    box-shadow: inset 0 200px 200px 200px rgb(0 0 0 / 10%);
  }

  &:disabled {
    background: linear-gradient(90deg, theme('colors.brand.gradientfrom60') 0%, theme('colors.brand.gradientto60') 100%);
    cursor: default;
  }
}

.btn-filter,
.btn-sort {
  font-weight: 600;
  color: theme('colors.slate.500');
  border: 1px solid theme('colors.slate.200');
  background: white;
  padding: 3px 12px;
  min-width: 48px;
  @include respond-above(xs) {
    min-width: 120px;
  }

  ate-icon {
    color: theme('colors.slate.500');
  }
}

.close-icon {
  width: 24px;
  height: 24px;
}

.btn-success {
  background: theme('colors.green.600');
  color: white;
  border: none;
}

.btn-reject {
  border: 1.5px solid theme('colors.slate.200');
  background: white;
  color: theme('colors.red.700');
}

.btn-icon {
  @apply tw-box-border tw-h-5 tw-w-5 tw-cursor-pointer tw-rounded-lg tw-border-x tw-border-y tw-border-solid tw-border-slate-300 tw-bg-white tw-p-2.5 tw-text-slate-600;
}

.btn-clear {
  cursor: pointer;
  display: flex;
  background: transparent;
  border: none;
  outline: none;
  padding: unset;
  justify-content: flex-start;
  align-items: center;
  color: var(--Default-slate-grey-600, theme('colors.slate.600'));
  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within,
  &:hover,
  &:visited {
    border: none;
    outline: none;
  }
}

.btn-review {
  border: none;
  position: relative;
  border-radius: 4px;
  background: linear-gradient(to right, #6a287e, #c6426e);
  &::before {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background-color: white;
    border-radius: 2px;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.48;
  }
  ate-icon {
    color: #6a287e;
  }
  span {
    background: var(--Default-Brand-Gradient, linear-gradient(90deg, #6a287e 0%, #c6426e 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
  }
}

.btn-sort-mobile {
  span {
    font-size: 14px;
    font-weight: 400;
  }
}

.btn-menu {
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: theme('colors.slate.500');
  background: white;
  border: 1.5px solid #d7dee7;
  border-radius: 4px;
  padding: 6px 12px;
}

.notification {
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.notification-success {
  background: theme('colors.green.100');
  color: theme('colors.green.600');
  p {
    color: theme('colors.slate.600') !important;
  }
}

.notification-alert {
  background: theme('colors.red.100');
  color: theme('colors.red.700');
  p {
    color: theme('colors.slate.600') !important;
  }
}

.gradient-anchor-tag {
  background: linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  cursor: pointer;

  &:after {
    content: '';
    background: linear-gradient(90deg, theme('colors.brand.gradientfrom') 0%, theme('colors.brand.gradientto') 100%);
    height: 1px;
    width: 100%;
    display: block;
  }
}
.mat-mdc-autocomplete-panel {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  box-shadow: none !important;
  max-height: calc(var(--vh, 1vh) * 35) !important;
}

.mat-mdc-autocomplete-panel > .mat-mdc-optgroup > .mat-mdc-option {
  padding-left: theme('padding.6') !important;
  padding-right: theme('padding.6') !important;
}
.mat-mdc-autocomplete-panel > .mat-mdc-optgroup > .mat-mdc-optgroup-label {
  cursor: pointer;
  padding-right: theme('padding.6');
  min-height: 20px !important;
  padding-top: theme('padding.2') !important;
  padding-bottom: theme('padding.2') !important;

  &:hover {
    background: theme('colors.slate.100');
  }
  & > .mdc-list-item__primary-text {
    font-weight: 600;
  }

  > span {
    display: flex !important;
    width: 100%;

    > ate-icon {
      margin-left: auto;
    }
  }
}

.mat-mdc-autocomplete-panel > .mat-mdc-optgroup > .mat-mdc-option {
  min-height: 20px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  transition: background 100ms ease-in-out !important;

  &:hover {
    background: theme('colors.slate.100');
  }

  & > .mdc-list-item__primary-text {
    font-size: 14px !important;
    color: theme('colors.slate.800') !important;
    min-height: 20px !important;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 100%;
  }
}

.autocomplete-match-highlight {
  text-decoration: underline;
  font-weight: bold;
}

#no-matches-select > span {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

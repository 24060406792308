@import '../styles/mixins';

app-event-request-inbox,
app-quotes {
  section {
    background: white;
    > ate-button-control {
      width: 100%;
    }
  }

  header {
    padding: 20px 0;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;

      h1 {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
      }
    }
  }

  app-event-request-list-item {
    cursor: pointer;
  }

  app-active-empty-message-box,
  app-empty-message {
    margin: 0 0 20px;
  }

  .error {
    border: 1px solid #910505;
    width: 90%;
    max-width: 300px;
    margin: 20px auto;
    ::ng-deep {
      p {
        color: #910505;
      }

      ate-icon {
        color: transparent;
      }
    }
  }

  app-active-empty-message-box .empty-message-button {
    width: 100%;
  }

  .desktop {
    display: none;
  }

  @include respond-above(lg) {
    section {
      padding: 0;
      background: initial;
      border-top: 0;
      border-bottom: 0;

      > ate-button-control {
        display: none;
      }
    }

    header {
      background: white;

      > div {
        max-width: 1140px;
        margin: 0 auto;

        h1 {
          font-weight: 700;
          font-size: 28px;
          line-height: 36px;
        }
      }
    }

    .container {
      margin: 0 auto;
      max-width: 1280px;
      box-sizing: border-box;
    }

    .desktop {
      display: block;
    }

    .submit-request {
      background: linear-gradient(255.03deg, #f7f8fa 0%, #f1f3f8 100%);
      border: 2px dashed #d7dee7;
      border-radius: 12px;
      padding: 40px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;

      &.full {
        grid-column: span 2;
      }

      ate-icon {
        @apply tw-font-default;
        color: #c6426e;
        border: 2px solid #c6426e;
        padding: 4px;
        border-radius: 80px;
        margin-right: 12px;
      }
    }

    app-active-empty-message-box,
    app-empty-message {
      grid-column: span 2;
    }

    app-active-empty-message-box .empty-message-button {
      max-width: 218px;
      margin: 0 auto;
      display: flex;
      height: initial;
    }
  }
}

.tiles-container {
  max-width: 1280px;
  margin: auto;
}
